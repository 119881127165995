import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import Progress from "react-progressbar";
import Slider from "react-slick";
import { StickyContainer, Sticky } from "react-sticky";
import { Link, Events, animateScroll } from "react-scroll";
import _throttle from "lodash/throttle";
import axios from "axios";
import { toast } from "react-toastify";

import ImgCta from "assets/images/sub-topic/img-cta.png";
import ImgLoader from "assets/images/spinner-opacity.gif";
import Button from "components/elements/button";
import { NextArrow, PrevArrow } from "components/arrows";

import { SimpleCrumbs, WPContent } from "components";

import { GlobalChartStyles } from "components/data-viz";

import SubTopicContainer from "./index.style";

import queryString from "query-string";
import { Helmet } from "react-helmet";

class SubTopic extends Component {
  state = {
    percentage: 0,
    activeSection: "",
    isLinking: false,
    votes: 0,
  };

  handleScroll = _throttle(() => {
    const pageContentOffsetTop = document
      .getElementById("page_content")
      .getBoundingClientRect().top;
    const pageContentHeight = document
      .getElementById("page_content")
      .getBoundingClientRect().height;

    if (
      pageContentOffsetTop < 0 &&
      Math.abs(pageContentOffsetTop) + window.innerHeight < pageContentHeight
    ) {
      const percentage =
        ((Math.abs(pageContentOffsetTop) + window.innerHeight) /
          pageContentHeight) *
        100;

      this.setState({
        percentage,
      });
    }
  }, 100);

  async componentDidMount() {
    try {
      document.getElementById("header").classList.add("subtopic");
      const { hash } = window.location;
      const urlParams = queryString.parse(window.location.search);
      const urlParamsTitle = urlParams.title || false;
      const self = this;
      const topicData = this.props.data;
      const {
        resources,
        google_spreadsheet_id: googleSheetId,
        chart_options: chartOptions,
      } = topicData.data.acf;
      const getVotes = await axios.post("/.netlify/functions/votes", {
        name: this.props.name,
        subtopic: true,
      });
      this.setState({
        votes: getVotes.data.votes,
      });

      if (googleSheetId && googleSheetId.length > 0) {
        import("components/data-viz").then(({ Chart, Data }) => {
          (async function() {
            await Data(googleSheetId).then(function() {
              if (chartOptions) {
                (chartOptions || []).forEach(function(obj) {
                  const chart = Chart.lookup(obj.title);
                  if (chart && obj.options)
                    chart.update(window.Function(`return (${obj.options})`)());
                });
              }

              if (urlParamsTitle) {
                document
                  .getElementsByClassName("data-viz")
                  .forEach(function(el) {
                    if (el.dataset.chart === urlParamsTitle) {
                      setTimeout(() => {
                        animateScroll.scrollTo(el.getBoundingClientRect().y);
                      }, 100);
                    }
                  });
              } else if (hash !== "") {
                setTimeout(() => {
                  animateScroll.scrollTo(
                    document
                      .getElementById(hash.substr(1))
                      .getBoundingClientRect().y
                  );
                }, 100);
              } else {
                document.addEventListener("scroll", self.handleScroll, {
                  passive: true,
                });
                Events.scrollEvent.register("begin", self.handleBeginScroll);
                Events.scrollEvent.register("end", self.handleEndScroll);
              }
            });
          })();
        });
      }

      const statusColumnsElement = document.getElementById("status_columns");
      const statusColumnsElementOriginal = document.getElementById(
        "status_columns_original"
      );
      if (statusColumnsElement) {
        statusColumnsElement.parentNode.replaceChild(
          statusColumnsElementOriginal,
          statusColumnsElement
        );
      } else {
        statusColumnsElementOriginal.style.display = "none";
      }

      const resourcesSliderElement = document.getElementById(
        "resources_slider"
      );
      const resourcesSliderElementOriginal = document.getElementById(
        "resources_slider_original"
      );

      if (resources && resourcesSliderElement) {
        resourcesSliderElement.parentNode.replaceChild(
          resourcesSliderElementOriginal,
          resourcesSliderElement
        );
      } else {
        resourcesSliderElementOriginal.style.display = "none";
        resourcesSliderElement.parentNode.style.display = "none";
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    document.getElementById("header").classList.remove("subtopic");
    document.removeEventListener("scroll", this.handleScroll, {
      passive: true,
    });
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  handleVote = async (event) => {
    if (event.classList.contains("loading")) return false;
    event.classList.add("loading");
    event.style = "background-color: #324d6a";
    event.innerHTML = `<img src=${ImgLoader} alt="" width="30px" />`;
    // axios.post('/.netlify/functions/subscribe', data)

    const tmpSubtopic = document.createElement("textarea");
    tmpSubtopic.innerHTML = this.props.data.data.title.rendered;
    const subtopic = tmpSubtopic.value;
    const email = localStorage.getItem("email") || false;
    const tracking = localStorage.getItem("user") || false;
    const data = {
      isVoting: true,
      upVote: true,
      downVote: false,
      subtopic: subtopic,
      email: email,
      user: tracking,
    };
    window.track.buttonClicked({
      clickText: "Vote",
      clickPath: window.utils.url.getLocation().pathname,
    });
    const subscriber = await axios
      .post("/.netlify/functions/subscribe", data)
      .then((res) => {
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("user", res.data.user);
        return res.data;
      });
    const user = await axios
      .post("/.netlify/functions/user", subscriber)
      .then((re) => {
        return {
          subtopic: subtopic,
          topics: re.data.topics,
          tracking: re.data.user,
        };
      });
    const db = await axios.post("/.netlify/functions/vote", user).then((r) => {
      event.style = "background-color: #112E51";
      event.innerHTML = `Vote`;
      event.classList.remove("loading");
      return r.data.success;
    });
    const msg = db
      ? "Thanks for voting!"
      : "Oops, you've already voted for this subtopic.";
    const type = db ? "success" : "error";
    toast(msg, {
      type: type,
      className: "notify-default",
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      newestOnTop: true,
    });
    const getVotes = await axios.post("/.netlify/functions/votes", {
      name: this.props.name,
      subtopic: true,
    });
    this.setState({
      votes: getVotes.data.votes,
    });
  };

  handleBeginScroll = () => {
    this.setState({
      isLinking: true,
    });
  };

  handleEndScroll = (to) => {
    this.props.history.replace(`#${to}`);
    this.setState({
      isLinking: false,
      activeSection: to,
    });
  };

  handleSetActive = (to) => {
    if (to && !this.state.isLinking) {
      this.props.history.replace(`#${to}`);

      this.setState({
        activeSection: to,
      });
    }
  };

  render() {
    const topicData = this.props.data;
    const { percentage, activeSection, votes } = this.state;
    const votesText = Number(votes) === 1 ? "vote" : "votes";
    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            variableWidth: false,
          },
        },
      ],
    };
    const bannerImage = topicData.data.better_featured_image
      ? topicData.data.better_featured_image.source_url
      : "";
    const statusColumns = topicData.data.acf.columns;
    const { resources } = topicData.data.acf;
    const { navigateSections = [] } = topicData.data;

    const parser = new DOMParser();
    const pageTitle = parser.parseFromString(
      topicData.data.title.rendered,
      "text/html"
    ).documentElement.textContent;

    return (
      <>
        <Helmet>
          <title>{pageTitle} | Understanding Houston</title>
          {/* <meta name="description" content={topicData.data.acf.topic_summary} /> */}
        </Helmet>
        <SubTopicContainer
          className={`subtopic subtopic-${topicData.data.slug}`}
        >
          <GlobalChartStyles />
          <div
            className="banner"
            style={{ backgroundImage: `url(${bannerImage})` }}
          />
          <section className="content">
            <StickyContainer>
              <aside className="sticky-container">
                <Sticky topOffset={0}>
                  {({ style, isSticky }) => (
                    <div
                      className={`sidebar-wrapper ${isSticky && "sticky"}`}
                      style={style}
                    >
                      <div className="sidebar">
                        <Progress completed={percentage} />
                        <div className="vote-container">
                          <div className="vote-intro mobile-hidden">
                            <h4>Vote for what matters to you</h4>
                            <p className="sm">
                              What issues do you think need attention,
                              resources, and collaborative action?
                            </p>
                          </div>
                          <div className="vote-box">
                            <div className="vote-actions">
                              <Button
                                className="vote"
                                onClick={(e) => this.handleVote(e.target)}
                              >
                                Vote
                              </Button>
                              <p className="sm">
                                <strong>{`${votes} ${votesText}`}</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="search-container"></div>

                      <div className="section-list">
                        <h5
                          className={`${activeSection === "overview" &&
                            "active"}`}
                        >
                          <Link
                            to="overview"
                            activeClass="active"
                            smooth
                            spy
                            duration={500}
                            onSetActive={this.handleSetActive}
                          >
                            Overview
                          </Link>
                        </h5>
                        <ul>
                          <li
                            className={`parent ${activeSection !== "overview" &&
                              "active"}`}
                          >
                            <Link
                              to="the_data"
                              activeClass="active"
                              smooth
                              spy
                              duration={500}
                              onSetActive={this.handleSetActive}
                            >
                              The Data
                            </Link>
                          </li>
                          {navigateSections.map((section, i) => (
                            <li
                              key={i}
                              className={`${section.id === activeSection &&
                                "active"}`}
                            >
                              <Link
                                activeClass="active"
                                spy
                                to={section.id}
                                smooth
                                duration={500}
                                onSetActive={this.handleSetActive}
                              >
                                {section.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </Sticky>
              </aside>
              <article className="main-content element" id="containerElement">
                <span id="content_top" className="element" />
                <div className="page-content" id="page_content">
                  <SimpleCrumbs
                    className="page-thumbs"
                    style={{
                      backgroundColor: "#112E51",
                    }}
                    items={[
                      {
                        label: "Explore the Data",
                      },
                      {
                        label: topicData.parent.title.rendered,
                        to: `/topic/${topicData.parent.slug}`,
                      },
                      {
                        label: topicData.data.title.rendered,
                        to: `/topic/${topicData.parent.slug}/${topicData.data.slug}`,
                      },
                    ]}
                  />
                  <div className="wrapper">
                    <WPContent>{topicData.data.content.rendered}</WPContent>
                    <div className="over-wrapper" id="status_columns_original">
                      <div className="statuses">
                        {statusColumns &&
                          statusColumns.length > 0 &&
                          statusColumns.map((column, i) => (
                            <div className="status" key={i}>
                              <img src={column.image.url} alt="status" />
                              <div className="text">
                                <WPContent>{column.content}</WPContent>
                                {column.explore_link !== "" && (
                                  <Link
                                    to={column.explore_link}
                                    smooth
                                    duration={500}
                                  >
                                    Explore
                                  </Link>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div id="resources_slider_original">
                      <Slider {...settings}>
                        {resources &&
                          resources.map((resource, index) => (
                            <div key={index}>
                              <div className="slide-content">
                                <h4>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: resource.title,
                                    }}
                                  />
                                </h4>
                                <p className="xs">
                                  {resource.name !== "" && resource.name}
                                  {resource.date !== "" && (
                                    <span>. {resource.date}</span>
                                  )}
                                </p>
                              </div>
                            </div>
                          ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </article>
            </StickyContainer>
          </section>
          <div className="get-involved">
            <div className="wrapper">
              <div className="content">
                <h3>Discover ways to get involved.</h3>
                <p>
                  Building a more vibrant Houston region is only possible with
                  you.
                </p>
                <RouterLink to="/get-involved">
                  <Button>Learn More</Button>
                </RouterLink>
              </div>
              <img src={ImgCta} alt="woman" />
            </div>
          </div>
        </SubTopicContainer>
      </>
    );
  }
}

export default SubTopic;
