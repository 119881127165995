import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ImgLogoWhite from "assets/images/logo-white-v2.svg";
import ImgKinderLogo from "assets/images/logo-kinder-footer.png";
import ImgGhcfLogo from "assets/images/ghcf-logo.png";
import { FooterTopicMenu } from "components/topic-menu";
import CurrentYear from "components/show-current-year";
import SocialLinks from "../socialLinks";
import FooterContainer from "./index.style";
import postscribe from "postscribe";

const Footer = () => {
  const [formLoaded, setFormLoaded] = useState(false);
  useEffect(() => {
    const script = `
      <script
      type="text/javascript"
      src="https://ghcf.formstack.com/forms/js.php/uh_newsletter">
      </script>`;

    postscribe("#newsletter_form", script);

    setFormLoaded(true);
  }, []);

  return (
    <FooterContainer id="footer" role="contentinfo">
      <div className="footer-top">
        <div className="container">
          <div className="logo-column">
            <div className="column">
              <img src={ImgLogoWhite} alt="White Logo" className="logo" />
              <SocialLinks />
            </div>
          </div>
          <div className="menu-column">
            <div className="column">
              <h4>
                <NavLink to="/the-initiative">WHO WE ARE</NavLink>
              </h4>
              <ul className="page-links initiative">
                <li>
                  <NavLink to="/the-initiative">About</NavLink>
                </li>
                <li>
                  <NavLink to="/the-big-picture">What We Do</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Articles & Insights</NavLink>
                </li>
                <li>
                  <NavLink to="/faqs">FAQs</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="column">
              <h4>
                <NavLink to="/explore-the-data">DATA</NavLink>
              </h4>
              <FooterTopicMenu className="page-links" />
            </div>
            <div className="column">
              <h4>
                <NavLink to="/get-involved">GET INVOLVED</NavLink>
              </h4>
              <ul className="page-links">
                <li>
                  <NavLink to="/get-involved">Vote</NavLink>
                </li>
                <li>
                  <NavLink to="/events">Attend an Event</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Give Feedback</NavLink>
                </li>
                <li>
                  <NavLink to="/contact?mode=story">Share a Story</NavLink>
                </li>
                <li>
                  <a
                    href="https://ghcf.kimbia.com/understandinghoustondonation"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Donate (opens in a new tab)"
                  >
                    Donate
                  </a>
                </li>
              </ul>
            </div>
            <div className="column">
              <h4>Never Miss a Story</h4>
              <div className="subscribe-form" id="newsletter-footer">
                <div
                  id="newsletter_form"
                  style={{ display: formLoaded ? "block" : "none" }}
                />
              </div>
            </div>
            <div className="logos">
              <img
                src={ImgGhcfLogo}
                alt="Greater Houston Community Foundation logo"
              />
              <img src={ImgKinderLogo} alt="Rice Kinder logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="left">
            <SocialLinks />
            <ul>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <a
                  href="https://baalspots.com/"
                  target="_blank"
                  aria-label="Donate (opens in a new tab)"
                >
                  Credits
                </a>
              </li>
            </ul>
            <p className="xs copyright">
              &copy; <CurrentYear /> Understanding Houston. All rights reserved.
            </p>
          </div>
          <div className="right">
            <img
              src={ImgGhcfLogo}
              alt="The Greater Houston Community Foundation logo"
              className="logo-ghcf"
            />
            <img
              src={ImgKinderLogo}
              alt="The Kinder Institute for Urban Research logo"
              className="logo-kinder"
            />
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
