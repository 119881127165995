import React, { Component } from "react";
import Headroom from "react-headroom";
import { NavLink } from "react-router-dom";
import logoU from "assets/images/logo-u-v2.svg";
// import logoText from "assets/images/logo-text-v2.svg";
import { TopicMenuDisplay, defaultTopics } from "components/topic-menu";
import HeaderContainer from "./index.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  state = {
    isExploreMenuOpen: true,
  };

  closeExploreMenu = () => {
    this.setState({ isExploreMenuOpen: false });
  };

  openExploreMenu = () => {
    this.setState({ isExploreMenuOpen: true });
  };

  render() {
    const exploreMenuOptions = {
      isOpen: this.state.isExploreMenuOpen,
      close: this.closeExploreMenu,
      toggle: (
        // TODO: add correct path
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <NavLink to="/explore-the-data" role="menuitem">
          <p className="xs" onMouseOver={this.openExploreMenu}>
            Explore The Data
          </p>
        </NavLink>
      ),
      align: "right",
      animate: false,
      textAlign: "left",
    };

    return (
      <header className="menu-bar" id="header">
        <Headroom disableInlineStyles>
          <HeaderContainer>
            <nav className="nav-bar">
              <div className="logo">
                <NavLink to="/">
                  <img
                    src={logoU}
                    alt="Understanding Houston Logo"
                    className="logo-u"
                  />
                </NavLink>
              </div>
              <div className="nav-menu">
                <ul>
                  <li>
                    <TopicMenuDisplay
                      {...exploreMenuOptions}
                      content={this.props.content}
                    />
                  </li>
                  <li className="dd-menu dd-menu-right">
                    <NavLink to="/the-initiative" role="menuitem">
                      <p className="xs">Who We Are</p>
                    </NavLink>
                    <div className="dd-menu-items">
                      <ul className="dd-items-left">
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://www.understandinghouston.org/the-initiative#ourApproach"
                            role="menuitem"
                            title="Our Approach"
                          >
                            Our Approach
                          </a>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://www.understandinghouston.org/the-initiative#aboutUs"
                            role="menuitem"
                            title="About Us"
                          >
                            About Us
                          </a>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://www.understandinghouston.org/the-initiative#advisory"
                            role="menuitem"
                            title="Advisory Committee"
                          >
                            Advisory Committee
                          </a>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://www.understandinghouston.org/the-initiative#supporters"
                            role="menuitem"
                            title="Supporters"
                          >
                            Supporters
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="/the-big-picture" role="menuitem">
                      <p className="xs">What We Do</p>
                    </NavLink>
                  </li>
                  <li className="dd-menu dd-menu-right get-involved">
                    <NavLink to="/get-involved" role="menuitem">
                      <p className="xs">Get Involved</p>
                    </NavLink>
                    <div className="dd-menu-items">
                      <ul className="dd-items-left">
                        <li className="nested-dd-menu nested-reverse">
                          <NavLink
                            to="/contact"
                            role="menuitem"
                            title="Contact Us"
                          >
                            Contact Us
                          </NavLink>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <NavLink to="/events" role="menuitem" title="Events">
                            Events
                          </NavLink>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://ghcf.kimbia.com/understandinghoustondonation"
                            role="menuitem"
                            title="Donate"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Donate
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="dd-menu dd-menu-right articles-reports">
                    <NavLink to="/blog" role="menuitem">
                      <p className="xs">Articles & Reports</p>
                    </NavLink>
                    <div className="dd-menu-items">
                      <ul className="dd-items-left">
                        <li className="nested-dd-menu nested-reverse">
                          <NavLink
                            to="/blog"
                            role="menuitem"
                            title="Articles & Insights"
                          >
                            Articles & Insights
                          </NavLink>
                        </li>
                        <li className="nested-dd-menu nested-reverse">
                          <a
                            href="https://api.understandinghouston.org/wp-content/uploads/2024/03/Understanding-Houston-Summary-Report-2023.pdf"
                            role="menuitem"
                            title="Summary Report"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Summary Report
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="li-search">
                    <NavLink
                      to="/search"
                      aria-label="Search"
                      role="menuitem"
                      title="Search"
                    >
                      <span className="btn-search-container">
                        <span className="btn-search">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                        <p className="xs btn-search-label">Search</p>
                      </span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </HeaderContainer>
        </Headroom>
      </header>
    );
  }
}

Header.defaultProps = {
  content: defaultTopics,
};

export default Header;
