import React from "react";

import PostTag from "./postTag";

const LabelCategories = ({ blog }) => (
  <PostTag bgcolor="#112E51">
    {blog._embedded["wp:term"] &&
      blog._embedded["wp:term"][0].map((category, i) => (
        <span key={i} style={{ color: "#fff" }}>
          {category.name} {i < blog._embedded["wp:term"][0].length - 1 && " | "}
        </span>
      ))}
  </PostTag>
);

export default LabelCategories;
